import React from 'react'
import { CiLinkedin } from "react-icons/ci";
import { FiGithub } from "react-icons/fi";
import { CiMail } from "react-icons/ci";
import { IoDocumentTextOutline } from "react-icons/io5";
const Socials = () => {
  return (
    <div className="flex flex-col left-0  md:ml-10">
    <ul className="py-5">
        <li className="flex py-3">
            <a href="https://www.linkedin.com/in/ruchit-thaker-56340b1b0/" target='_blank' rel="noreferrer"> 
                <CiLinkedin size={30} color='black'/>
            </a>

        </li>
        <li className="flex py-3">
            <a href="https://github.com/ruchitthaker" target='_blank' rel="noreferrer">
                <FiGithub size={30} color='black'/>
            </a>
        </li>
        <li className="flex py-3">
            <a href="mailto:rrthaker@usc.edu" rel="noreferrer">
                <CiMail size={30} color='black'/>
            </a>
            </li>
            <li className="flex py-3">
            <a href="https://drive.google.com/file/d/1W2Ty6TQPXBsMFgQs0IWDSRB0pRvU3ddr/view?usp=sharing" target='_blank' rel="noreferrer">
                <IoDocumentTextOutline size={30} color='black'/>
            </a>
            </li>
    </ul>
</div>
  )
}

export default Socials
