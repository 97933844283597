import React, { useState } from 'react'
import Modal from './Modal.jsx';

const Experience = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedExperience, setSelectedExperience] = useState(null);
  
    const openModal = (experience) => {
      setSelectedExperience(experience);
      setIsOpen(true);
    };
  
    const closeModal = () => {
      setIsOpen(false);
      setSelectedExperience(null);
    };
    const experiences = [
        {
            role: "Software Developer, Intern",
            company: "Airy AI. , USA",
            duration: "Jun 2024 - Present",
            stack: "ReactJS, IndexedDB, OAuth",
            description: ["Currently developing a cross-platform web application with React to replicate iOS functionality.","Implementing IndexedDB for efficient browser-based data storage","In progress: Integrating OAuth authentication for secure user access"]
        },
        {
          role: " Full Stack Web Developer, Intern",
          company: "CYCORP, India",
          duration: "Jun 2022 - Jul 2022",
          stack: "HTML, CSS, JavaScript, NodeJS, Express, MongoDB,AWS, Netlify Adobe Illustrator.",
          description: ["Developed REST APIs and seamlessly integrated them with frontend.",
             "Analyzed UI needs, formulated a mockup for the same and developed the frontend accordingly.",
            "Defined and managed the web application’s database and deployed it on a remote server."]
        },
        {
            role: "Socio Tech, Intern",
            company: "Aapka Sahara Foundation, India",
            duration: "Jun 2021 - Jul 2021",
            stack: "Python, Django, Firebase, HTML, CSS, JavaScript,Heroku, Adobe Illustrator",
            description: ["Conducted extensive UI research tailored to a specific age group and designed mockups accordingly.","Spearheaded backend development, leveraging Django’s features for rapid development and high security","Administered the database and ensured secure user authentication with Firebase."]
        },
        {
            role: "Social Media Marketing, Intern",
            company: "Tap Intuit, USA",
            duration: "Jul 2020 - Aug 2020",
            stack: "Adobe Illustrator, Adobe Photoshop",
            description: ["Planned campaigns, built strategies for growth and generated reports based on the company’s strategies.","Designed graphics for social media as well as the company’s website."]
        },
        // Add more experiences as needed
      ];

      
  return (
    <div name="Experience" className="min-h-screen w-full mt-2 relative pt-24 md:mb-12 md:pt-16">
    <div className="w-full">
      <h1 className="text-3xl md:text-5xl font-bold text-center">Experience</h1>
      <div className="w-32 bg-black h-0.5 mx-auto mt-2"></div>
    </div>

    <div className="text-center max-w-screen-lg mx-auto flex flex-col items-center h-full px-8">
      <div className="relative container mx-auto p-4">
        <div className="border-l-2 border-gray-300 absolute h-full left-1/2 transform -translate-x-1/2"></div>
        <div className="space-y-8">
          {experiences.map((exp, index) => (
            <div
              key={index}
              className={`flex flex-col sm:flex-row ${index % 2 === 0 ? 'sm:justify-start' : 'sm:justify-end'} relative`}
              onClick={() => openModal(exp)}
            >
              <div className="w-full sm:w-1/2 p-4 cursor-pointer">
                <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
                  <h3 className="text-xl font-semibold mb-2">{exp.role}</h3>
                  <p className="text-gray-600 font-medium text-lg">{exp.company}</p>
                  <p className="text-gray-500">{exp.duration}</p>
                </div>
              </div>
              <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-4 h-4 bg-gray-500 rounded-full border-4 border-white"></div>
            </div>
          ))}
        </div>
        <Modal isOpen={isOpen} onClose={closeModal} content={selectedExperience} />
      </div>
    </div>
  </div>
  )
}

export default Experience