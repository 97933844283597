import React from 'react'

const Modal = ({ isOpen, onClose, content }) => {
    if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full mx-4 relative">
        <button
          className="absolute text-3xl top-2 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
        <h3 className="text-xl font-semibold mb-2">{content.role}</h3>
        <p className="text-gray-600 text-lg font-medium">{content.company}</p>
        <p className="text-gray-500 mb-4">{content.duration}</p>
        <hr />
        <p className="text-gray-700 mt-4">{content.stack}</p>
        {Array.isArray(content.description) ? (
                <ul className="list-disc pl-5 space-y-1 text-left mt-4 text-gray-700">
                  {content.description.map((desc, index) => (
                    <li key={index}>{desc}</li>
                  ))}
                </ul>
              ) : (
                <p>{content.description}</p>
              )}
      </div>
    </div>
  )
}

export default Modal