import React from "react";
import profilePicture from "../assets/profilePicture.png";

import Socials from '../components/Socials.jsx'
import { Link } from 'react-scroll';


const Home = () => {
  return (
    <div name="Home" className="h-screen w-full relative pt-2 md:pt-0 ">
      <div className="max-w-screen-2xl mx-auto flex flex-col justify-between items-center h-full px-8 md:flex-row ">
    <div className="hidden md:top-[35%] top-[15%] md:block md:mr-20">
    <Socials/>
    </div>
        <div className="flex flex-col mr-10 h-full justify-center order-2 md:order-1 mx-auto md:mx-0 text-justify">
          <h1 className="md:text-7xl font-bold py-2 text-4xl">Ruchit Thaker</h1>
          <div className="flex flex-row items-center">
            <div className="w-20 bg-gray-400 h-0.5"></div>
            <div>
              <p className="text-lg md:text-2xl font-medium text-gray-600 py-4 mx-2 px-2">
                {" "}
                Software Developer
              </p>
            </div>
          </div>

          <div>
          <p className="text-lg md:text-xl text-gray-600 py-4">
            I'm a Software Developer and USC Master's student currently based in
            Los Angeles.I leverage my experience from multiple tech internships to develop innovative solutions and am passionate about creating impactful technology.
          </p>
          </div>

          <div className="flex flex-row items-center justify-center md:justify-start">
            <Link to="Contact" smooth duration={500}>
            <button className="bg-gray-700 text-white px-6 py-3 rounded-lg mt-5 hover:scale-110 duration-190 cursor-pointer">
              Contact
            </button>
            </Link>
            
          </div>


        </div>
        <div className="w-2/3 md:w-full  mt-10 md:mt-0 order-1 md:order-2 flex flex-row justify-center items-center space-x-8">
        <div className="md:hidden left-0">
    <Socials/>
    </div>
          <img src={profilePicture} alt="profile" className="grayscale"/>
        </div>
      </div>
    </div>
  );
};

export default Home;
