import React from 'react'
import { CiLinkedin } from "react-icons/ci";
import { FiGithub } from "react-icons/fi";
import { CiMail } from "react-icons/ci";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoMdPin } from "react-icons/io";
const Contact = () => {
  return (
    <div name="Contact" className="flex flex-col justify-center items-center max-h-screen-lg relative w-full  pt-32 md:pt-16 mt-2">
    <div className='w-full'>
        <h1 className='text-3xl md:text-5xl font-bold text-center'>
            Contact
            
        </h1>
        <div className="w-32 bg-black h-0.5 mx-auto mt-2"></div>
        
    </div>

   
  <div className="text-center max-w-screen mx-auto flex flex-row items-center  justify-center px-8">
 
  <div className="min-h-screen w-full pt-32 md:pt-16">
      <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row items-center px-4 sm:px-6 lg:px-8 space-x-8">
     
        <div className="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0 px-5">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900">Get in Touch</h2>
          <p className="mt-4 text-lg text-gray-700 text-justify">
            Feel free to reach out through any of the platforms below or send me a message directly!
          </p>
          <div className="flex flex-row space-x-8 left-0  mt-8">
          <a href="https://www.linkedin.com/in/ruchit-thaker-56340b1b0/" target='_blank' rel="noreferrer">
                <CiLinkedin size={30} color='black'/>
            </a>
            <a href="https://github.com/ruchitthaker" target='_blank' rel="noreferrer">
                <FiGithub size={30} color='black'/>
            </a>
           
            <a href="https://drive.google.com/file/d/158FLe2V6wuiV1Jq6cJ2jiEOYlVgu062m/view?usp=sharing" target='_blank' rel="noreferrer">
                <IoDocumentTextOutline size={30} color='black'/>
            </a>
   
</div>
          <div className="flex flex-row space-x-2 mt-5">
          <CiMail size={30} color='black'/>
          <p>
            <a href="mailto:rrthaker@usc.edu" className="text-lg hover:underline">
                rrthaker@usc.edu
                </a>
         </p>
          </div>
          <div className="flex flex-row space-x-2 mt-2">
          <IoMdPin size={30} color='black'/>
          <p>
            
                Los Angeles, CA
              
         </p>
          </div>
        
        
        </div>
        <div className="w-full md:w-1/2 bg-white p-8 rounded-lg shadow-lg">
          <form action="https://getform.io/f/pbnvvqzb" method='POST'>
            <div className="mb-6">
              <input
                type="text"
                id="name"
                name="name"
                placeholder='Name'
                className="w-full border-b-2 border-gray-300 focus:border-black focus:outline-none py-2"
                required
              />
            </div>
            <div className="mb-6">
              <input
                type="email"
                id="email"
                name="email"
                placeholder='Email'
                className="w-full border-b-2 border-gray-300 focus:border-black focus:outline-none py-2"
                required
              />
            </div>
            <div className="mb-6">
              <textarea
                id="message"
                name="message"
                rows="2"
                placeholder='Message'
                className="w-full border-b-2 border-gray-300 focus:border-black focus:outline-none py-2"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-gray-700 text-white px-6 py-2 rounded-lg hover:scale-110 duration-190 cursor-pointer"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
    </div>
    
</div>
  )
}

export default Contact