import React from 'react'
import Navbar from './navbar.jsx';
import Home from './Home.jsx';
import About from './About.jsx';
import Experience from './Experience.jsx';
import Projects from './Projects.jsx';
import Skills from './Skills.jsx';
import Contact from './Contact.jsx';
const Main = () => {
  return (
    <div className='flex flex-col'> 
    <Navbar/>
    <Home/>
    <About/>
    <Experience/>
    <Projects/>
    <Skills/>
    <Contact/>
    </div>
  )
}

export default Main