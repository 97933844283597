import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
//import {img1,img2,img3,img4,img5,img6,img7} from '../assets/MediStop';
const ProjectModal = ({ showModal, onClose, project }) => {
  if (!showModal) return null;

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-screen-lg mx-4 p-4 max-h-screen overflow-y-auto">
        <button className="float-right text-3xl md:text-5xl  mt-1 mr-4 text-gray-600" onClick={onClose}>
        &times;
        </button>
        <div className="py-4">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 text-center">{project.title}</h2>
          <div className="mt-4 max-h-lg max-w-lg mx-auto">
            <Carousel showThumbs={true} autoPlay infiniteLoop>
              {project.images.map((imageSrc, index) => (
                <div key={index}>
                  <img src={imageSrc} alt={`Slide ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          </div>
          <div className="mt-8">
            <h3 className="text-xl font-bold tracking-tight text-gray-900">About the Project</h3>
            <div className="mt-4 text-gray-500">{Array.isArray(project.description) ? (
                <ul className="list-disc pl-5 space-y-1 text-left mt-4 text-gray-700">
                  {project.description.map((desc, index) => (
                    <li key={index}>{desc}</li>
                  ))}
                </ul>
              ) : (
                <p>{project.description}</p>
              )}</div>
            <h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">Tech Stack</h3>
            <div className="mt-4 flex flex-wrap gap-2">
              {project.techStack.map((tech, index) => (
                <span key={index} className="bg-black text-white rounded-full px-3 py-1 text-sm font-semibold">
                  {tech}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectModal;
