import React, { useState } from 'react'
import { HiSquares2X2 } from "react-icons/hi2";
import { FaTimes } from "react-icons/fa";
import { Link } from 'react-scroll';
const Navbar = () => {

    const [nav,setNav] = useState(false);
    const navlinks = [{
        id:1,
        link: 'Home'
    },
{
    id:2,
    link: 'About'
},{
    id:3,
    link: 'Experience'
},
{
    id:4,
    link: 'Projects'},
{
    id:5,
    link: 'Skills'
},
{
    id:6,
    link: 'Contact'
}];
  return (
    <div className="flex justify-between items-center w-full h-15 py-5 md:h-15  px-4 fixed mb-5 bg-white z-10">
        <div>
            <h1 className="text-2xl ml-2 font-bold ">
                RT.
            </h1>
        </div>

        <ul className="hidden md:flex">
            {navlinks.map((link) => (
                <li key = {link.id} className="px-4 cursor-pointer font-medium text-gray-500 hover:scale-110 duration-190 ">
                <Link to={link.link} smooth duration={500}>{link.link}</Link>
            </li>
            ))}
            

        </ul>

        <div onClick={()=>setNav(!nav)} className='cursor-pointer pr-4 z-10 md:hidden'>
            {nav? <FaTimes size={30} color='white'/>  :  <HiSquares2X2 size={30}/> }
        </div>

        {nav && (<ul className='flex flex-col justify-center items-center absolute top-0 right-0 w-full h-screen bg-black text-white'>
            {nav && navlinks.map((link) => (
                <li key = {link.id} className="px-4 cursor-pointer font-medium py-6 ">
                <Link to={link.link} smooth duration={500} onClick={()=>setNav(!nav)}>{link.link}</Link>
            </li>
            ))}
        </ul>)}
    </div>
  )
}

export default Navbar