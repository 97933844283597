import React from 'react'
import { SiBootstrap,SiSwift,SiCanva,SiAdobeillustrator,SiGithub,SiMongodb, SiExpress, SiReact, SiAngular,SiTailwindcss, SiFlask,SiHtml5, SiCss3,SiJavascript,SiTypescript,SiPython,SiFirebase,SiMysql, SiPostgresql } from "react-icons/si";
import { FaNode } from "react-icons/fa";
import { DiDjango } from "react-icons/di";
import { TbBrandOauth } from "react-icons/tb";

const Skills = () => {
  return (
    <div name="Skills" className="min-h-screen w-full mt-2 pt-32 md:pt-16 md:mb-24">
    <div className='w-full'>
        <h1 className='text-3xl md:text-5xl font-bold text-center'>
            Technology
            
        </h1>
        <div className="w-32 bg-black h-0.5 mx-auto mt-2"></div>
        
    </div>

   
  <div className="text-center max-w-screen-lg mx-auto flex flex-col items-center h-full px-8">
  <div>
        <p className='text-center font-light text-xl md:text-xl  mt-3 py-5'>
            Currently working on:
            
        </p>
        <div className='flex flex-row gap-x-6 md:gap-x-10'>
<SiMongodb size={50} />
<SiExpress size={50} />
<SiReact size={50} />
<FaNode size={50} />

                </div> 
                <p className='text-xl font-medium py-5'>MERN Stack</p>
    </div>
     <div>
     <p className='text-center font-light text-xl md:text-xl py-5'>
            Other Technologies
            
        </p>

        <div className='flex flex-col mx-auto justify-center items-center gap-y-12'>
        <div className='flex flex-row gap-x-6 md:gap-x-10'>
        <SiAngular size={50}/>
        <SiTailwindcss size={50}/>
        <DiDjango size={50}/>
        <SiFlask size={50}/>
        <SiHtml5 size={50}/>
          </div>
          <div className='flex flex-row gap-x-6 md:gap-x-10'>
        <SiCss3 size={50}/>
        <SiJavascript size={50}/>
        <SiTypescript size={50}/>
        
          </div>
          <div className='flex flex-row gap-x-6 md:gap-x-10'>
        <SiPython size={50}/>
        <SiFirebase size={50}/>
        <SiMysql size={50}/>
        <SiPostgresql size={50}/>
        <TbBrandOauth size={50}/>
          </div>
          <div className='flex flex-row gap-x-6 md:gap-x-10'>
        <SiGithub size={50}/>
        <SiAdobeillustrator size={50}/>
        <SiCanva size={50}/>
        
          </div>
          <div className='flex flex-row gap-x-6 md:gap-x-10'>
        <SiSwift size={50}/>
        <SiBootstrap size={50}/>
          </div>
          
        </div>
        
     </div>
      

    </div>
    
</div>
  )
}

export default Skills