import React, { useState } from 'react';
import ProjectModal from './ProjectModal';
import img1 from '../assets/MediStop/img1.png';
import img2 from '../assets/MediStop/img2.png';
import img3 from '../assets/MediStop/img3.png';
import img4 from '../assets/MediStop/img4.png';
import img5 from '../assets/MediStop/img5.png';
import img6 from '../assets/MediStop/img6.png';
import img7 from '../assets/MediStop/img7.png';

import Org1 from '../assets/Organiser/Org1.png';
import Org2 from '../assets/Organiser/Org2.png';
import Org3 from '../assets/Organiser/Org3.png';
import org4 from '../assets/Organiser/org4.png';
import org5 from '../assets/Organiser/org5.png';
import org6 from '../assets/Organiser/org6.png';

import gps1 from '../assets/GPS/gps1.png';
import gps2 from '../assets/GPS/gps2.png';
import gps3 from '../assets/GPS/gps3.png';
import gps4 from '../assets/GPS/gps4.png';
import gps5 from '../assets/GPS/gps5.png';
import gps6 from '../assets/GPS/gps6.png';
import gps7 from '../assets/GPS/gps7.png';
import gps8 from '../assets/GPS/gps8.png';

import stock1 from '../assets/StockApp/stock1.png';
import stock2 from '../assets/StockApp/stock2.png';
import stock3 from '../assets/StockApp/stock3.png';


import sm1 from '../assets/SocialMedia/sm1.png';
import sm2 from '../assets/SocialMedia/sm2.png';
import sm3 from '../assets/SocialMedia/sm3.png';
import sm4 from '../assets/SocialMedia/sm4.png';
import sm5 from '../assets/SocialMedia/sm5.png';
import sm6 from '../assets/SocialMedia/sm6.png';
import sm7 from '../assets/SocialMedia/sm7.png';

const Projects = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);

  const projects = [
    {
      title: 'ORGANiser',
      description: ['Implemented a blockchain-based organ donation project, aiming to address organ scarcity.','Created and deployed smart contracts to establish a transparent and tamper-resistant organ donation ecosystem.'],
      images: [
       Org1,Org2,Org3,org4,org5,org6
      ],
      techStack: ['HTML', 'CSS', 'JavaScript',"NodeJS", "Solidity","Truffle"],
      imageSrc: org6,
      imageAlt: 'Organiser'
    },
    {
      title: 'GPS – Grab Parking Spot',
      description: ['Developed a solution for hassle-free parking, enabling users to locate and reserve parking spaces.','Efficiently managed occupancy of parking spots to optimize the system.'],
      images: [
       gps1,gps2,gps3,gps4,gps5,gps6,gps7,gps8
      ],
      techStack: ['HereMaps','HTML', 'CSS', 'JavaScript',"NodeJS", "Express","MongoDB","Adobe Illustrator"],
      imageSrc: gps1,
      imageAlt: 'GPS – Grab Parking Spot'
    },
    {
      title: 'MediStop',
      description: ['Initiated and built a comprehensive medicine procurement platform, streamlining processes by automating prescription verification.','Efficiently managed occupancy of parking spots to optimize the system.'],
      images: [
        img1,img2,img3,img4,img5,img6,img7
      ],
      techStack: ["Python", "Django","Microsoft-OCR", "Firebase", "HTML", "CSS", 'JavaScript', "Adobe Illustrator"],
      imageSrc: img1,
      imageAlt: 'MediStop'
    },
    {
        title: <p><a href="https://assn3-frontend.wl.r.appspot.com/search/home" target="_blank" rel="noopener noreferrer" className='text-blue text-underline'>StockApp</a></p>,
        description: ['Developed a comprehensive stock market analysis application using the MEAN stack.', 'Integrated highcharts for dynamic data visualization.', 'Utilized Polygon and Finnhub APIs for real-time stock data.', 'Hosted on Google Cloud Platform (GCP) for scalable and reliable performance.', <p className='text-blue text-underline'><a href="https://assn3-frontend.wl.r.appspot.com/search/home" target="_blank" rel="noopener noreferrer" className='text-blue text-underline'>View demo</a></p>],
        images: [
          stock1,stock2,stock3
        ],
        techStack: ["MongoDB", "Express", "Angular", "NodeJS", "Highcharts", "Polygon API", "Finnhub API", "REST"],
        imageSrc: stock1,
        imageAlt: 'StockApp'
      },

      {
        title: 'Designs(SocialMedia & Logo)',
        description: [
          'Crafted visually engaging designs for influencers and companies on social media platforms.',
          'Utilized a variety of design tools including Photoshop, Canva, Illustrator, and Adobe XD.',
          'Developed and implemented effective brand strategies to enhance online presence.',
          <p className='text-blue text-underline'><a href="https://assn3-frontend.wl.r.appspot.com/search/home" target="_blank" rel="noopener noreferrer" className='text-blue text-underline'>View demo</a></p>
        ],
        images: [
          sm1,sm2,sm3,sm4,sm5,sm6,sm7
        ],
        techStack: ["Photoshop", "Canva", "Illustrator", "Adobe XD"],
        imageSrc: sm1,
        imageAlt: 'Designs(SocialMedia & Logo)'
      }
  ];

  const handleProjectClick = (project) => {
    setCurrentProject(project);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div name="Projects" className="min-h-screen w-full relative pt-32 md:pt-16">
      <div className="w-full">
        <h1 className="text-3xl md:text-5xl font-bold text-center">Projects</h1>
        <div className="w-32 bg-black h-0.5 mx-auto mt-2"></div>
      </div>
      <div className="max-w-screen-xl mx-auto">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mx-auto py-16 sm:py-24 lg:max-w-none lg:py-32">
            <div className="mt-1 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
              {projects.map((project) => (
                <div
                  key={project.title}
                  className="group relative mb-5 cursor-pointer px-5 md:px-0 "
                  onClick={() => handleProjectClick(project)}
                >
                  <div className="relative  h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                    <img
                      src={project.imageSrc}
                      alt={project.imageAlt}
                      className="h-full w-full object-cover object-center grayscale"
                    />
                  </div>
                  <h3 className="mt-6 text-sm text-gray-500">
                    <span className="absolute inset-0" />
                    FullStack
                  </h3>
                  <p className="text-2xl font-semibold text-gray-900">{project.title}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {currentProject && (
        <ProjectModal showModal={showModal} onClose={handleCloseModal} project={currentProject} />
      )}
    </div>
  );
};

export default Projects;
